import React from "react";
import { Link, useLocation } from "react-router-dom"; // Import Link for internal routing

const Footer = () => {
  const location = useLocation();
  const handleComplianceClick = (id) => {
    if (location.pathname === "/compliance") {
      document.getElementById(id).scrollIntoView({
        behavior: "smooth",
      });
    }
  };
  return (
    <footer>
      <div className="container">
        <div className="row pb-4">
          <div className="col-lg-3 col-md-6 col-sm-6">
            {/* Footer logo */}
            <Link to="/">
              <img
                src="/assets/images/logo2.svg"
                alt="Logo"
                style={{ width: "200px" }}
              />
            </Link>
            <p>
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry when an unknown took a galley of type and scrambled.
            </p>
            <div className="d-flex flex-row gap-2">
              <a href="#">
                <i className="fa-brands fa-facebook"></i>
              </a>
              <a href="#">
                <i className="fa-brands fa-instagram"></i>
              </a>
              <a href="#">
                <i className="fa-brands fa-linkedin-in"></i>
              </a>
            </div>
          </div>

          <div className="col-lg-3 col-md-6 col-sm-6">
            <h5>Get in Touch</h5>
            <div className="d-flex flex-column gap-4">
              <div className="d-flex">
                <div>
                  <i className="fa-solid fa-location-dot"></i>
                </div>
                <a
                  href="https://www.google.com/maps/search/?api=1&query=Near+PGKM+School,+next+to+Risha+Enclave,+Pune,+411048"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Near PGKM School, next to Risha Enclave, Pune, 411048
                </a>
              </div>
              <div className="d-flex align-items-center">
                <div>
                  <i className="fa-solid fa-envelope"></i>
                </div>
                <a href="mailto:contactus@bestworks-india.com">
                  contactus@bestworks-india.com
                </a>
              </div>
              <div className="d-flex align-items-center">
                <div>
                  <i className="fa-solid fa-phone"></i>
                </div>
                <a href="tel:+917499025674">+91 74990 25674</a>
              </div>
            </div>
          </div>

          <div className="col-lg-3 col-md-6 col-sm-4 col-6">
            <h5>Explore</h5>
            <ul className="d-flex flex-column gap-4">
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>
                <Link to="/about">About Us</Link>
              </li>
              <li>
                <Link to="/spaces">Spaces</Link>
              </li>
              <li>
                <Link to="/contact#contact-form">Contact Us</Link>
              </li>
            </ul>
          </div>

          {/* <div className="col-lg-2 col-md-4 col-sm-4 col-4">
            <h5>Locations</h5>
            <ul className="d-flex flex-column gap-4">
              <li>
                <a href="#">New York City</a>
              </li>
              <li>
                <a href="#">Atlanta</a>
              </li>
              <li>
                <a href="#">Chicago</a>
              </li>
              <li>
                <a href="#">Soho East</a>
              </li>
              <li>
                <a href="#">Los Angeles</a>
              </li>
            </ul>
          </div> */}

          <div className="col-lg-3 col-md-6 col-sm-4 col-6">
            <h5>Support</h5>
            <ul className="d-flex flex-column gap-4">
              <li>
                <Link
                  to="/compliance#privacy-policy"
                  onClick={() => handleComplianceClick("privacy-policy")}
                >
                  Privacy Policy
                </Link>
              </li>
              <li>
                <Link
                  to="/compliance#terms-and-conditions"
                  onClick={() => handleComplianceClick("terms-and-conditions")}
                >
                  Terms & Conditions
                </Link>
              </li>
              <li>
                <Link
                  to="/compliance#faqs"
                  onClick={() => handleComplianceClick("faqs")}
                >
                  FQA
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <hr />
      <div className="copyright d-flex align-items-center justify-content-center container">
        <p>Copyright © 2024 Bestworks Co-working Spaces</p>
      </div>
    </footer>
  );
};

export default Footer;
