import React from "react";
import CustomSwiper from "./CustomSwiper";
const Testimonials = () => {
  const slides = [
    <div className="customer d-flex align-items-center position-relative">
      {/* <div className="customer-bg">
        <figure><img src="assets/images/index/customer.webp" alt="customer" /></figure>
      </div> */}
      <div>
        <p className="customer-p">
          BestWorks has been a total game-changer for me. The space is super
          cool, the vibe is chill, and the people are awesome. I couldn't ask
          for a better place to work.
        </p>
        <div className="d-flex align-items-center justify-content-between detail">
          <div>
            <div>
              <i className="fa-sharp fa-solid fa-star"></i>
              <i className="fa-sharp fa-solid fa-star"></i>
              <i className="fa-sharp fa-solid fa-star"></i>
              <i className="fa-sharp fa-solid fa-star"></i>
              <i className="fa-sharp fa-solid fa-star"></i>
            </div>
            <h6>Avinash Patel</h6>
            <p className="clr">Freelancer</p>
          </div>
          <div className="comma">
            <figure>
              <img src="assets/images/icon/coma.svg" alt="comma" />
            </figure>
          </div>
        </div>
      </div>
    </div>,

    <div className="customer d-flex align-items-center position-relative">
      {/* <div className="customer-bg">
        <figure><img src="assets/images/index/custmor2.webp" alt="customer" /></figure>
      </div> */}
      <div>
        <p className="customer-p">
          I've been really impressed with BestWorks. The facilities are
          top-notch, and the location is super convenient. It's the perfect
          place to get work done and connect with other professionals.
        </p>
        <div className="d-flex align-items-center justify-content-between detail">
          <div>
            <div>
              <i className="fa-sharp fa-solid fa-star"></i>
              <i className="fa-sharp fa-solid fa-star"></i>
              <i className="fa-sharp fa-solid fa-star"></i>
              <i className="fa-sharp fa-solid fa-star"></i>
              <i className="fa-sharp fa-solid fa-star"></i>
            </div>
            <h6>Nikhil Rao</h6>
            <p className="clr">Professional</p>
          </div>
          <div className="comma">
            <figure>
              <img src="assets/images/icon/coma.svg" alt="comma" />
            </figure>
          </div>
        </div>
      </div>
    </div>,

    <div className="customer d-flex align-items-center position-relative">
      {/* <div className="customer-bg">
        <figure><img src="assets/images/index/custmor3.webp" alt="customer" /></figure>
      </div> */}
      <div>
        <p className="customer-p">
          BestWorks has exceeded my expectations. The staff is friendly and
          helpful, and the workspace is always clean and well-maintained. I
          highly recommend it to anyone looking for a professional and
          productive environment.
        </p>
        <div className="d-flex align-items-center justify-content-between detail">
          <div>
            <div>
              <i className="fa-sharp fa-solid fa-star"></i>
              <i className="fa-sharp fa-solid fa-star"></i>
              <i className="fa-sharp fa-solid fa-star"></i>
              <i className="fa-sharp fa-solid fa-star"></i>
              <i className="fa-sharp fa-solid fa-star"></i>
            </div>
            <h6>Ritika Sharma</h6>
            <p className="clr">Professional</p>
          </div>
          <div className="comma">
            <figure>
              <img src="assets/images/icon/coma.svg" alt="comma" />
            </figure>
          </div>
        </div>
      </div>
    </div>,
    <div className="customer d-flex align-items-center position-relative">
      {/* <div className="customer-bg">
       <figure><img src="assets/images/index/customer.webp" alt="customer" /></figure>
     </div> */}
      <div>
        <p className="customer-p">
          BestWorks has been a total game-changer for me. The space is super
          cool, the vibe is chill, and the people are awesome. I couldn't ask
          for a better place to work.
        </p>
        <div className="d-flex align-items-center justify-content-between detail">
          <div>
            <div>
              <i className="fa-sharp fa-solid fa-star"></i>
              <i className="fa-sharp fa-solid fa-star"></i>
              <i className="fa-sharp fa-solid fa-star"></i>
              <i className="fa-sharp fa-solid fa-star"></i>
              <i className="fa-sharp fa-solid fa-star"></i>
            </div>
            <h6>Avinash Patel</h6>
            <p className="clr">Freelancer</p>
          </div>
          <div className="comma">
            <figure>
              <img src="assets/images/icon/coma.svg" alt="comma" />
            </figure>
          </div>
        </div>
      </div>
    </div>,
  ];

  const swiperSettings = {
    spaceBetween: 50,
    slidesPerView: 2,
    slidesPerGroup: 2,
    pagination: { clickable: true },
    breakpoints: {
      0: {
        slidesPerView: 1,
        slidesPerGroup: 1,
        spaceBetween: 10,
      },

      480: {
        slidesPerView: 1,
        slidesPerGroup: 1,
        spaceBetween: 10,
      },
      768: {
        slidesPerView: 2,
        slidesPerGroup: 2,
        spaceBetween: 20,
      },
      // 1024: {
      //   slidesPerView: 3,
      //   slidesPerGroup: 3,
      //   spaceBetween: 30,
      // },
      // 1280: {
      //   slidesPerView: 4,
      //   slidesPerGroup: 4,
      //   spaceBetween: 40,
      // },
      // 1440: {
      //   slidesPerView: 5,
      //   slidesPerGroup: 5,
      //   spaceBetween: 50,
      // },
    },
  };
  return (
    <section className="customers">
      <div className="container">
        <h5 className="text-center h6-color">Customers Trust</h5>
        <h2>
          What <span className="active-color">People say</span> about us
        </h2>
        <p className="customers-main-p">
          Lorem Ipsum is simply dummy text of the printing and typesetting
          industry. Lorem Ipsum has been the industry's.
        </p>
        <div className="slider_customers" data-aos="zoom-in">
          <CustomSwiper slides={slides} settings={swiperSettings} />
        </div>
      </div>
    </section>
  );
};

export default Testimonials;
