import React, { useEffect, useState } from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { toast } from "react-toastify";
import withLayout from "../hoc/withLayout";
import { useLocation } from "react-router-dom";

const Contact = () => {
  const location = useLocation();
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    mobile: "",
    landline: "",
    whatsapp: "",
    purpose: "",
    configuration: "",
    day: "",
    month: "",
    year: "2024",
    hours: "",
    minutes: "",
    duration: "",
    message: "",
    consent: false,
  });

  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : value,
    });

    // Validate the field being updated
    validateField(name, type === "checkbox" ? checked : value);
  };

  const validateField = (name, value) => {
    let error = "";
    switch (name) {
      case "firstName":
        if (!value) error = "First Name is required";
        break;
      case "lastName":
        if (!value) error = "Last Name is required";
        break;
      case "email":
        if (!value) {
          error = "Email is required";
        } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value)) {
          error = "Invalid email address";
        }
        break;
      case "mobile":
        if (!value) {
          error = "Mobile Number is required";
        } else if (!/^\+?[1-9]\d{1,14}$/.test(value)) {
          error = "Invalid mobile number";
        }
        break;
      case "purpose":
        if (!value) error = "Purpose is required";
        break;
      case "configuration":
        if (!value) error = "Configuration is required";
        break;
      case "day":
        if (!value) error = "Day is required";
        break;
      case "month":
        if (!value) error = "Month is required";
        break;
      case "year":
        if (!value) error = "Year is required";
        break;
      case "hours":
        if (!value) error = "Hours are required";
        break;
      case "minutes":
        if (!value) error = "Minutes are required";
        break;
      case "duration":
        if (!value) error = "Duration is required";
        break;
      case "consent":
        if (!value) error = "Consent is required";
        break;
      case "message":
        if (value.length > 200)
          error = "Message should be less than 200 characters";
      default:
        break;
    }

    setErrors({
      ...errors,
      [name]: error,
    });
  };

  const validateForm = () => {
    const newErrors = {};
    if (!formData.firstName) newErrors.firstName = "First Name is required";
    if (!formData.lastName) newErrors.lastName = "Last Name is required";
    if (!formData.email) {
      newErrors.email = "Email is required";
    } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.email)) {
      newErrors.email = "Invalid email address";
    }
    if (!formData.mobile) {
      newErrors.mobile = "Mobile Number is required";
    } else if (!/^\+?[1-9]\d{1,14}$/.test(formData.mobile)) {
      newErrors.mobile = "Invalid mobile number";
    }
    if (!formData.purpose) newErrors.purpose = "Purpose is required";
    if (!formData.configuration)
      newErrors.configuration = "Configuration is required";
    if (!formData.day) newErrors.day = "Day is required";
    if (!formData.month) newErrors.month = "Month is required";
    if (!formData.year) newErrors.year = "Year is required";
    if (!formData.hours) newErrors.hours = "Hours are required";
    if (!formData.minutes) newErrors.minutes = "Minutes are required";
    if (!formData.duration) newErrors.duration = "Duration is required";
    if (!formData.consent) newErrors.consent = "Consent is required";
    return newErrors;
  };

  const handlePhoneChange = (value) => {
    setFormData({
      ...formData,
      mobile: value,
    });

    // Validate the mobile field
    validateField("mobile", value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const validationErrors = validateForm();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
    } else {
      // Handle form submission logic here
      console.log(formData);
      setErrors({});
      toast.success("Form submitted successfully");
      setFormData({
        firstName: "",
        lastName: "",
        email: "",
        mobile: "",
        landline: "",
        whatsapp: "",
        purpose: "",
        configuration: "",
        day: "",
        month: "",
        year: "2024",
        hours: "",
        minutes: "",
        duration: "",
        message: "",
        consent: false,
      });
    }
  };

  useEffect(() => {
    if (location.hash) {
      const element = document.querySelector(location.hash);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [location.hash]);

  return (
    <section className="contact-form">
      <div className="container">
        <div className="row">
          <div className="col-lg-6 col-md-6" data-aos="fade-up">
            <div className="Message-form">
              <div className="d-flex gap-4">
                <div>
                  <i className="fa-solid fa-location-dot"></i>
                </div>
                <div>
                  <h5>Address</h5>
                  <p>Near PGKM School, next to Risha Enclave, Pune, 411048</p>
                </div>
              </div>
              <div className="d-flex gap-4">
                <div>
                  <i className="fa-solid fa-envelope"></i>
                </div>
                <div>
                  <h5>Email</h5>
                  <p>contactus@bestworks-india.com</p>
                </div>
              </div>
              <div className="d-flex gap-4">
                <div>
                  <i className="fa-solid fa-phone"></i>
                </div>
                <div>
                  <h5>Phone</h5>
                  <p>+91 74990 25674</p>
                </div>
              </div>
            </div>
          </div>
          <div
            className="col-lg-6 col-md-6 d-flex justify-content-center flex-column"
            data-aos="fade-down"
          >
            <h2>Prime Location, Unmatched Connectivity</h2>
            <p className="m-0">
              BestWorks is strategically located in Kondhwa, Pune, offering easy
              access to major IT parks, business districts, and transportation
              hubs. Enjoy seamless connectivity with the rest of the city.
            </p>
            <ul>
              <li>
                <strong>Nearby Areas:</strong> Swargate, Market Yard,
                Lullanagar, Shreehans Nagar, Indira Nagar, Gagan Vihar,
                Bibwewadi, NIBM.
              </li>
              <li>
                <strong>Proximity to Tech Parks:</strong> Platinum Tech Park,
                Panchshil; BNY Mellon Innovation Centre; Science and Technology
                Park, Pune; EON IT Park; Hadapsar; Kharadi;
                Pimpri-Chinchwad-Bhosari Industrial Area.
              </li>
              <li>
                <strong>Transportation:</strong> Pune Junction Railway Station,
                Pune International Airport, Major Roads (Kushaba Zanjale Road,
                Jawaharlal Nehru Road, Swami Vivekanand Road, Pune Satara Road).
              </li>
            </ul>
          </div>

          <div id="Success-box2"></div>
        </div>

        <div id="contact-form" className="row mt-4">
          <div className="Message-form" data-aos="fade-up">
            <h4>Ready to Experience BestWorks?</h4>
            <form onSubmit={handleSubmit}>
              <div className="row">
                <div className="col-lg-6 col-md-6">
                  <input
                    type="text"
                    name="firstName"
                    id="firstName"
                    autoComplete="on"
                    placeholder="First Name"
                    value={formData.firstName}
                    onChange={handleChange}
                    style={{ borderColor: errors.firstName ? "red" : "" }}
                  />
                  {errors.firstName && (
                    <p style={{ color: "red" }}>{errors.firstName}</p>
                  )}

                  <input
                    type="email"
                    name="email"
                    id="email"
                    autoComplete="on"
                    placeholder="Email Address"
                    value={formData.email}
                    onChange={handleChange}
                    style={{ borderColor: errors.email ? "red" : "" }}
                  />
                  {errors.email && (
                    <p style={{ color: "red" }}>{errors.email}</p>
                  )}

                  <input
                    type="number"
                    name="landline"
                    id="landline"
                    autoComplete="on"
                    placeholder="Landline"
                    value={formData.landline}
                    onChange={handleChange}
                  />

                  <select
                    className="form-select"
                    name="purpose"
                    id="purpose"
                    value={formData.purpose}
                    onChange={handleChange}
                    style={{ borderColor: errors.purpose ? "red" : "" }}
                  >
                    <option value="">Select Purpose</option>
                    <option value="site_visit">Site Visit</option>
                    <option value="book_space">Book a Space</option>
                  </select>
                  {errors.purpose && (
                    <p style={{ color: "red" }}>{errors.purpose}</p>
                  )}

                  <select
                    className="form-select"
                    name="day"
                    id="day"
                    value={formData.day}
                    onChange={handleChange}
                    style={{ borderColor: errors.day ? "red" : "" }}
                  >
                    <option value="">Day</option>
                    {[...Array(31)].map((_, i) => (
                      <option key={i} value={i + 1}>
                        {i + 1}
                      </option>
                    ))}
                  </select>
                  {errors.day && <p style={{ color: "red" }}>{errors.day}</p>}

                  <select
                    className="form-select"
                    name="year"
                    id="year"
                    value={formData.year}
                    onChange={handleChange}
                    style={{ borderColor: errors.year ? "red" : "" }}
                  >
                    <option value="">Year</option>
                    <option value="2024">2024</option>
                  </select>
                  {errors.year && <p style={{ color: "red" }}>{errors.year}</p>}

                  <select
                    className="form-select"
                    name="minutes"
                    id="minutes"
                    value={formData.minutes}
                    onChange={handleChange}
                    style={{ borderColor: errors.minutes ? "red" : "" }}
                  >
                    <option value="">Minutes</option>
                    {[0, 15, 30, 45].map((minute) => (
                      <option key={minute} value={minute}>
                        {minute < 10 ? `0${minute}` : minute}
                      </option>
                    ))}
                  </select>
                  {errors.minutes && (
                    <p style={{ color: "red" }}>{errors.minutes}</p>
                  )}

                  <textarea
                    name="message"
                    id="message"
                    cols="30"
                    rows="4"
                    placeholder="Message"
                    value={formData.message}
                    style={{ borderColor: errors.message ? "red" : "" }}
                    onChange={handleChange}
                  ></textarea>
                  {errors.message && (
                    <p style={{ color: "red" }}>{errors.message}</p>
                  )}
                </div>

                <div className="col-lg-6 col-md-6">
                  <input
                    type="text"
                    name="lastName"
                    id="lastName"
                    autoComplete="on"
                    placeholder="Last Name"
                    value={formData.lastName}
                    onChange={handleChange}
                    style={{ borderColor: errors.lastName ? "red" : "" }}
                  />
                  {errors.lastName && (
                    <p style={{ color: "red" }}>{errors.lastName}</p>
                  )}

                  <PhoneInput
                    country={"in"}
                    value={formData.mobile}
                    onChange={(mobile) => {
                      handlePhoneChange(mobile);
                    }}
                    inputProps={{
                      name: "mobile",
                      id: "mobile",
                      placeholder: "Mobile Number",
                    }}
                    inputStyle={{
                      width: "100%",
                      borderRadius: "0px",
                      height: "42px",
                      marginLeft: "22px",
                      borderColor: errors.mobile ? "red" : "",
                    }}
                    containerClass="custom-phone-input"
                  />
                  {errors.mobile && (
                    <p style={{ color: "red" }}>{errors.mobile}</p>
                  )}

                  <input
                    type="number"
                    name="whatsapp"
                    id="whatsapp"
                    autoComplete="on"
                    placeholder="WhatsApp"
                    pattern="[0-9]{10}"
                    value={formData.whatsapp}
                    onChange={handleChange}
                    style={{ marginTop: "10px" }}
                  />

                  <select
                    className="form-select"
                    name="configuration"
                    id="configuration"
                    value={formData.configuration}
                    onChange={handleChange}
                    style={{ borderColor: errors.configuration ? "red" : "" }}
                  >
                    <option value="">Select Configuration</option>
                    <option value="desk">Desk</option>
                    <option value="cabin">Cabin</option>
                    <option value="meeting_room">Meeting Room</option>
                    <option value="conference_room">Conference Room</option>
                    <option value="entire_space">Entire Space</option>
                    <option value="exam_centre">Exam Centre</option>
                    <option value="event_enquiry">Event Enquiry</option>
                  </select>
                  {errors.configuration && (
                    <p style={{ color: "red" }}>{errors.configuration}</p>
                  )}

                  <select
                    className="form-select"
                    name="month"
                    id="month"
                    value={formData.month}
                    onChange={handleChange}
                    style={{ borderColor: errors.month ? "red" : "" }}
                  >
                    <option value="">Month</option>
                    {[
                      "January",
                      "February",
                      "March",
                      "April",
                      "May",
                      "June",
                      "July",
                      "August",
                      "September",
                      "October",
                      "November",
                      "December",
                    ].map((month, i) => (
                      <option key={i} value={month}>
                        {month}
                      </option>
                    ))}
                  </select>
                  {errors.month && (
                    <p style={{ color: "red" }}>{errors.month}</p>
                  )}

                  <select
                    className="form-select"
                    name="hours"
                    id="hours"
                    value={formData.hours}
                    onChange={handleChange}
                    style={{ borderColor: errors.hours ? "red" : "" }}
                  >
                    <option value="">Hours</option>
                    {[...Array(11)].map((_, i) => (
                      <option key={i} value={i + 9}>
                        {i + 9}
                      </option>
                    ))}
                  </select>
                  {errors.hours && (
                    <p style={{ color: "red" }}>{errors.hours}</p>
                  )}

                  <select
                    className="form-select"
                    name="duration"
                    id="duration"
                    value={formData.duration}
                    onChange={handleChange}
                    style={{ borderColor: errors.duration ? "red" : "" }}
                  >
                    <option value="">Select Duration</option>
                    <option value="1_hour">1 Hour</option>
                    <option value="1_day">1 Day</option>
                    <option value="1_week">1 Week</option>
                    <option value="1_month">1 Month</option>
                    <option value="custom_plan">Other/Customised Plan</option>
                  </select>
                  {errors.duration && (
                    <p style={{ color: "red" }}>{errors.duration}</p>
                  )}
                </div>
              </div>

              <div className="d-flex align-items-start gap-2 mt-4">
                <input
                  type="checkbox"
                  name="consent"
                  checked={formData.consent}
                  onChange={handleChange}
                  style={{
                    width: "15px",
                    borderColor: errors.consent ? "red" : "",
                  }}
                />
                <span>I consent to share my details.</span>
              </div>
              {errors.consent && (
                <p style={{ color: "red" }}>{errors.consent}</p>
              )}

              <div className="d-flex justify-content-center mt-4">
                <button className="hover2" type="submit">
                  Submit
                </button>
              </div>
            </form>
          </div>
          <div className="col-lg-6 col-md-6" data-aos="fade-up"></div>
          <div id="Success-box2"></div>
        </div>

        <div className="row mt-4">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d15137.79611972053!2d73.86164135483824!3d18.46330896102949!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bc2eb4821b82d27%3A0x6233bc75991ce16!2sBestWorks%20India%20CoWorking%20Space!5e0!3m2!1sen!2sin!4v1728936766278!5m2!1sen!2sin"
            width="600"
            height="450"
            style={{ border: 0 }}
            allowfullscreen
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>
      </div>
    </section>
  );
};

export default withLayout(Contact);
