import React from "react";
import { Link } from "react-router-dom";

const Solution = () => {
  return (
    <section className="Companies">
      <div className="companies-circle">
        <div className="companies-circle1"></div>
        <div className="companies-circle1"></div>
      </div>
      <div className="container">
        <div className="row d-flex gap-lg-0 gap-md-0 gap-sm-5 gap-4">
          <div className="col-lg-6 col-md-6">
            <figure>
              <img src="assets/images/offices/office1.jpg" alt="image" />
            </figure>
          </div>
          <div
            className="col-lg-6 col-md-6 d-flex flex-column"
            data-aos="fade-up"
          >
            <h2>Ideal for Seminars, Meetups, and Exam Centers</h2>
            <ul className="Companies-list">
              <li>
                Our spacious and well-equipped facilities are perfect for
                hosting seminars, workshops, and training sessions.
              </li>
              <li>
                We offer flexible rental options to accommodate your event
                needs.
              </li>
              <li>
                Our convenient location in Kondwa provides easy access for
                participants.
              </li>
              <li>
                We can provide additional services such as catering, audiovisual
                equipment, and on-site support.
              </li>
            </ul>
            <div>
              <Link to="/contact#contact-form" className="hover2">
                Book A Tour
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Solution;
