import React, { useEffect } from "react";
import withLayout from "../hoc/withLayout";
import FaqQuestions from "../components/FaqQuestions";
import PrivacyPolicy from "../components/PrivacyPolicy";
import TermsOfUse from "../components/TermsOfUse";
import { useLocation } from "react-router-dom";

const FAQ = () => {
  const location = useLocation();

  useEffect(() => {
    if (location.hash) {
      const element = document.querySelector(location.hash);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [location.hash]);
  return (
    <div>
      <FaqQuestions />
      <PrivacyPolicy />
      <TermsOfUse />
    </div>
  );
};

export default withLayout(FAQ);
