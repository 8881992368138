import React from "react";
import CustomSwiper from "./CustomSwiper";

const Features = () => {
  const swiperSettings = {
    spaceBetween: 10,
    slidesPerView: 3,
    slidesPerGroup: 3,
    pagination: { clickable: true },
    breakpoints: {
      0: {
        slidesPerView: 1,
        slidesPerGroup: 1,
        spaceBetween: 10,
      },
      480: {
        slidesPerView: 1,
        slidesPerGroup: 1,
        spaceBetween: 10,
      },
      768: {
        slidesPerView: 2,
        slidesPerGroup: 2,
        spaceBetween: 20,
      },
      1024: {
        slidesPerView: 3,
        slidesPerGroup: 3,
        spaceBetween: 30,
      },
      1280: {
        slidesPerView: 4,
        slidesPerGroup: 4,
        spaceBetween: 40,
      },
      1440: {
        slidesPerView: 5,
        slidesPerGroup: 5,
        spaceBetween: 50,
      },
    },
  };
  const slides = [
    <div className="amenities-card col">
      <img src="assets/icons/internet-speed-wifi.svg" alt="Icon" />
      <h5>High-speed internet</h5>
      <p>Stay connected and productive with our fast and reliable internet.</p>
    </div>,

    <div className="amenities-card col">
      <img src="assets/icons/user-pilot-tie.svg" alt="Icon" />
      <h5>24/7 security</h5>
      <p>Enjoy peace of mind with our round-the-clock security.</p>
    </div>,

    <div className="amenities-card col">
      <img src="assets/icons/meeting-alt.svg" alt="Icon" />
      <h5>Meeting and conference rooms</h5>
      <p>Host meetings and presentations in our well-equipped spaces.</p>
    </div>,

    <div className="amenities-card col">
      <img src="assets/icons/coffee-pot.svg" alt="Icon" />
      <h5>Pantry and tea/coffee area</h5>
      <p>Take a break and recharge in our communal area.</p>
    </div>,

    <div className="amenities-card col">
      <img src="assets/icons/parking.svg" alt="Icon" />
      <h5>Dedicated parking</h5>
      <p>Never worry about finding parking with our reserved spaces.</p>
    </div>,

    <div className="amenities-card col">
      <img src="assets/icons/lease.svg" alt="Icon" />
      <h5>Flexible lease terms</h5>
      <p>Choose the plan that best suits your needs.</p>
    </div>,

    <div className="amenities-card col">
      <img src="assets/icons/community.svg" alt="Icon" />
      <h5>Professional and supportive community</h5>
      <p>Connect with like-minded individuals and build your network.</p>
    </div>,
    <div className="amenities-card col">
      <img src="assets/icons/location.svg" alt="Icon" />
      <h5>Central location in Kondhwa</h5>
      <p>Enjoy easy access to transportation and amenities.</p>
    </div>,
    <div className="amenities-card col">
      <img src="assets/icons/seminars.svg" alt="Icon" />
      <h5>Space available for seminars, meetups, and exam centres</h5>
      <p>Host events and exams in our versatile spaces.</p>
    </div>,
  ];

  return (
    <section className="Amenities" data-aos="fade-down">
      <div className="container">
        <h2>
          Workspace <span className="active-color">Flexibility</span> At Its
          Best: Explore Our Features
        </h2>
        <p className="amenities-p">
          Lorem Ipsum is simply dummy text of the printing and typesetting
          industry. Lorem Ipsum has been the industry's standard dummy text.
        </p>
        {/* Use the Slider component from react-slick */}
        <CustomSwiper slides={slides} settings={swiperSettings} />
      </div>
    </section>
  );
};

export default Features;
