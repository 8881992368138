import React from "react";
import "../css/Privacy.css";

const TermsOfUse = () => {
  return (
    <div className="container" id="terms-and-conditions">
      <h1>TERMS & USAGE POLICY</h1>
      <p>
        This Terms & Usage Policy (“TUP”), together with the Membership
        Agreement and other agreements (collectively the “Agreement”), applies
        to and governs Your use of the services and serves as the agreement
        between BestWorks and its Affiliates (“We”, “Us,” or “Our”) and You
        (“You” or “Your”). You and We are each a “Party” and collectively
        referred to herein as the “Parties.” This Agreement is effective as of
        the date of execution of the Agreement.
      </p>
      <p>
        By signing the Membership Agreement, You agree to be bound by this TUP
        and all terms incorporated by reference. If You do not agree to these
        terms, do not use Our Services. We may make commercially reasonable
        changes to this Agreement from time to time. We will provide notice of
        any material changes to this Agreement. If the change has a material
        adverse impact on You, and You do not agree to the change, You must so
        notify Us within Thirty (30) days after receiving notice of the change.
        If You notify Us as required, then You will remain governed by the TUP
        in effect immediately prior to the change. If the affected Services are
        renewed, they will be renewed under the TUP current at the time of
        renewal.
      </p>
      <h4>Table of Contents:</h4>
      <ol>
        <li>1. Term and Termination</li>
        <li>2. Information Technology</li>
        <li>3. Fair Usage Policy</li>
        <li>4. General Provisions</li>
        <li>5. Defined Terms</li>
        <li>6. Benefits of Membership</li>
        <li>7. House Rules</li>
        <li>8. Membership Fees</li>
        <li>
          9. Changes to or Removal of Primary Member or Authorized Signatory
        </li>
        <li>
          10. Work Space Not Timely Available or Unavailable due to certain
          events
        </li>
        <li>11. Updating the Member List</li>
        <li>12. Our Reserved Rights</li>
        <li>13. Harassment</li>
        <li>14. Fraud</li>
      </ol>
      <h4>1. Term and Termination</h4>
      <p>
        I. Term. This Agreement will be effective when signed by both parties
        (“Monthly Usage Charge Commencement Date”); provided that we have no
        obligations to provide you with the Services until the later of (i) the
        date on which payment of your Interest Free Refundable Security Deposit
        and first month’s Membership Fee has been received by us or (ii) the
        Monthly Usage Charge Commencement Date. The initial Agreement Term and
        all subsequent Renewal Terms shall constitute the “Term”.
      </p>
      <p>
        II. Lock-in Period: The Lock-in Period is indicated on your Membership
        Agreement, if no Lock-in Period is indicated on your Membership
        Agreement, the default Lock-in Period shall commence on the Monthly
        Usage Charge Commencement Date and end one (1) month after the Monthly
        Usage Charge Commencement Date. This Agreement will continue until
        terminated in accordance with this Agreement.
      </p>
      <p>
        III. Cancellation Prior to Monthly Usage Charge Commencement Date by
        You. You cannot terminate this Agreement prior to the Monthly Usage
        Charge Commencement Date. In the event, You defaults w.r.t. moving in to
        the Work Space after signing of the Membership Agreement, You shall be
        liable to pay Monthly Usage Charges for the lock-in period.
      </p>
      <p>
        IV. Termination by You. You may terminate this Agreement by delivering
        to us written notice of the period agreed under the Membership Agreement
        and the termination will be effective on the later of the last Regular
        Business Day of the Termination Effective Month and the expiration of
        the Lock-in Period. No termination by you shall be effective during the
        Lock-in Period, and termination by you during the Lock-in Period is a
        breach of this Agreement. If you terminate this Agreement prior to the
        end of the Lock-in Period, your Monthly Membership Fee obligations for
        the remaining Lock-in Period shall become immediately due. In addition
        to any rights, claims and remedies we choose to pursue in our
        discretion, your Interest Free Refundable Security Deposit shall be
        forfeited immediately as a result of your breach.
      </p>
      <p>
        V. Termination or Suspension by Us. We may withhold Services or
        immediately terminate this Agreement:
      </p>
      <ul>
        <li>i. upon breach of this Agreement by you or any Member;</li>
        <li>
          ii. upon termination, expiration or material loss of our rights in the
          Premises;
        </li>
        <li>
          iii. if any outstanding fees are still due after we provide notice to
          you;
        </li>
        <li>
          iv. if you or any of your Members fail to comply with the terms and
          conditions of your Membership and House Rules, or any other policies
          or instructions provided by us or applicable to you; or
        </li>
        <li>
          v. at any other time, when we, in our sole discretion, see fit to do
          so. You will remain liable for past due amounts, and we may exercise
          our rights to collect due payment, despite termination or expiration
          of this Agreement.
        </li>
      </ul>
      <p>
        VI. Removal of property upon Termination. Prior to the termination or
        expiration of this Agreement, you will remove all of yours, your
        Members’, and your or their guests’ property from the Work Space and
        Building Premises. After providing you with reasonable notice, we will
        be entitled to dispose of any property remaining in or on the Work Space
        or Building Premises after the termination or expiration of this
        Agreement and will not have any obligation to store such property, and
        you waive any claims or demands regarding such property or our handling
        of such property. You will be responsible for paying any fees reasonably
        incurred by us regarding such removal. Following the termination or
        expiration of this Agreement, we will not forward or hold mail or other
        packages delivered to us.
      </p>
      <p>
        VII. Interest Free Refundable Security Deposit (IFRSD). The IFRSD will
        be held as a retainer for performance of all your obligations under the
        Agreement, including the Membership Fee Obligations, and is not intended
        to be a reserve from which Membership Fees or any other fees may be
        paid. In the event you owe us other fees, you may not rely on deducting
        them from the IFRSD, but must pay them separately. We will return the
        IFRSD, or any balance after deducting outstanding fees and other costs
        due to us, including any unsatisfied Membership Fee Obligations, to you
        by bank transfer or other method that we communicate to you within
        thirty (30) days (or earlier if required by applicable law) after the
        later of (i) the termination or expiration of this Agreement and
        vacation of the Work Space by you and (ii) the date on which you provide
        to us all account information necessary for us to make such payment.
        Return of the IFRSD is also subject to your complete performance of all
        your obligations under this Agreement, including any obligations in
        relation to the transfer of your registered office from Our address upon
        termination and the provision of documentary evidence of the same to us,
        full satisfaction of your Membership Fee Obligations and any other
        obligations applicable following termination or expiration of this
        Agreement.
      </p>
      <p>
        VIII. Delay in Handover of Work Space. In the event of any delays by you
        in handing over the Work Space to us after the termination or expiration
        of the Agreement, you agree to pay twice the applicable Membership Fee
        for every day of un-authorized occupation, beyond the date of expiry or
        termination of this Agreement. You agree and acknowledge that the
        damages provided for in clause are a genuine and reasonable pre-estimate
        of the damages that we may suffer as a result of your delay and failure
        to comply with your obligations in the manner contemplated and is not a
        penalty.
      </p>
      <h4>2. Information Technology</h4>
      <p>
        In order to utilize all the functionalities offered by us, it may be
        necessary to install software onto a Member’s computer, tablet, mobile
        device or other electronic equipment. In addition, a Member may request
        that we or an affiliate, or our agent or their service provider may help
        troubleshoot problems a Member may have with respect to printing,
        accessing the network connection or other issues. Regarding the
        foregoing, you agree that we and our affiliates:
      </p>
      <ul>
        <li>
          Are not responsible for any damage to any Member’s computer, tablet,
          mobile device or other electronic equipment, or otherwise to Member’s
          system, related to such technical support or downloading and
          installation of any software;
        </li>
        <li>
          Are not responsible for any malware/ spyware/ virus attack and other
          internet threats via shared internet connection provided to you.
        </li>
        <li>
          Do not assume any liability or warranty in the event that any
          manufacturer warranties are voided; and
        </li>
        <li>
          Do not offer any verbal or written warranty, either expressed or
          implied, regarding the success of any technical support.
        </li>
      </ul>
      <p>
        The Company provides shared Internet access to Members via a wired or
        wireless network connection. For those Members wishing to implement a
        private wired network, the Company may allow you to install a firewall
        device for your exclusive access and use, subject to Company IT
        approval, and you will be responsible for removal of the same. Prior to
        any such installation or removal, you shall coordinate with the Company
        IT team to discuss the actual setup, appropriate time, manner and means
        for such installation or removal and any additional fees that may result
        from the request. To the extent that we incur any costs in connection
        with such installation or removal, which are not otherwise paid by you,
        we shall deduct such costs from the Interest Free Refundable Security
        Deposit. You shall also be responsible for any monthly fees incurred
        relating to your private, secured wired network.
      </p>
      <h4>3. Fair Usage Policy</h4>
      <ul>
        <li>
          I. Under No circumstance will the Premises and its facilities be used
          for:
          <ul>
            <strong>Cyber-terrorism - </strong>which includes a person who
            denies access to an authorised personnel to a computer resource,
            accesses a protected system or introduces contaminant into a system,
            with the intention of threatening the unity, integrity, sovereignty
            or security of another entity or the country including and not
            limited to hacking or social engineering or phishing
          </ul>
          <li>
            <strong>Cyberbullying - </strong>which includes but not restricted
            to sending, posting, or sharing negative, harmful, false about
            someone else such as personal or private information about someone
            else causing embarrassment or humiliation.
          </li>
          <li>
            <strong> Cyber-Porn - </strong>Which includes but not restricted to
            capturing, transmitting or publishing images of a person's private
            parts without his/her consent or knowledge, publishing or
            transmitting images containing a sexual explicit act or conduct.
          </li>
          <li>
            <strong> Cheating or Cyber Fraud - </strong>which includes cheating
            using computer/internet resource or communication channel or public
            Internet Protocol (IP) of BestWorks.
          </li>
          <li>
            which includes cheating using computer/internet resource or
            communication channel or public Internet Protocol (IP) of BestWorks.
          </li>
        </li>
        <li>
          II. Members are prohibited to gain unauthorized access to any
          Services, or accounts, computer systems or networks connected to any
          of Our server or to any of the Services, or to other co-working
          associate’s resources through hacking, password mining or any other
          means.
        </li>
        <li>
          III. Video/Audio Streaming is disabled for the Members for
          Non-Educational Sites (ex-Netflix, Prime etc).
        </li>
        <li>
          IV. Members are recommended to have an antivirus installed on all
          computers to prevent mass infection via virus outbreak.
        </li>
        <li>
          V. Members are liable for the physical safety and maintenance of the
          IT assets which are brought by them to the Premises. The building
          staff will assist in the event of a mis-happening or loss by making
          available CCTV footage up to available days of the day of the
          incident.
        </li>
      </ul>
      <h4>4. General Provisions</h4>
      <p>
        <strong>I. Nature of the Agreement.</strong> The whole of the Work Space
        remains our property and in our possession and control. We are giving
        you the right to share with us the use of the Work Space so that we can
        provide the Services to you. Notwithstanding anything in this Agreement
        to the contrary, you and we agree that our relationship is not that of
        landlord-tenant or lessor-lessee and this Agreement in no way shall be
        construed as to grant you or any Member any title, easement, lien,
        possession or related rights in our business, the Premises, the Work
        Space or anything contained in or on the Building Premises or Work
        Space. This Agreement creates no tenancy interest, leasehold estate, or
        other real property interest.
      </p>
      <p>
        <strong>II. Relationship of the Parties.</strong> The parties hereto
        shall each be independent contractors in the performance of their
        obligations under this Agreement, and this Agreement shall not be deemed
        to create a fiduciary or agency relationship, or partnership or joint
        venture, for any purpose. Neither party will in any way misrepresent our
        relationship.
      </p>
      <p>
        <strong>III. Subordination.</strong> This Agreement is subject and
        subordinate to our lease/license with our landlord/licensor of the
        Premises and to any supplemental documentation and to any other
        agreements to which our lease/license with such landlord/licensor is
        subject to or subordinate. However, the foregoing does not imply any
        sublease or other similar relationship involving an interest in real
        property.
      </p>
      <p>
        <strong>IV. Notices.</strong> Any and all notices under this Agreement
        will be given via email or to the respective addresses mentioned on the
        Membership Agreement, and will be effective on the first business day
        after being received.
      </p>
      <p>
        <strong>V. Headings and Interpretation.</strong> The headings in this
        Agreement are for convenience only and are not to be used to interpret
        or construe any provision of this Agreement. Any use of “including,”
        “for example” or “such as” in this Agreement shall be read as being
        followed by “without limitation” where appropriate.
      </p>
      <p>
        <strong>VI. No Assignment.</strong> Except in connection with a merger,
        acquisition, corporate reorganization, or sale of all or substantially
        all of the shares or assets of you or your parent corporation, you may
        not transfer or otherwise assign any of your rights or obligations under
        this Agreement (including by operation of law) without our prior
        consent. We may assign this Agreement without your consent.
      </p>
      <p>
        <strong>VII. Compliance with Laws.</strong> You hereby represent and
        warrant that at all times you and your Members have conducted and will
        conduct your operations ethically and in accordance with all applicable
        laws.
      </p>
      <p>
        <strong>VIII. Entire Agreement.</strong> This Agreement, including the
        Membership Agreement, constitutes the entire agreement between the
        parties relating to the subject matter hereof and shall not be changed
        in any manner except by a writing executed by both parties or as
        otherwise permitted herein. All prior agreements and understandings
        between the parties regarding the matters described herein have merged
        into this Agreement.
      </p>
      <p>
        <strong>IX. No exclusivity.</strong> Nothing in this Agreement shall be
        construed as creating any obligations of exclusivity on us. You agree
        and acknowledge that we shall, at all times, be entitled to contract
        with and provide services similar or identical to the Services to third
        parties that are engaged in businesses similar and/or identical to the
        business carried on by you.
      </p>
      <h4>5. Defined Terms</h4>
      <p>
        <strong>a. Affiliates</strong> means any entity that
        <ul>
          <li>i. controls either directly or indirectly; or</li>
          <li>ii. is controlled directly or indirectly; or</li>
          <li>
            iii. is under common Control. “Control” in relation to any Party,
            means the power or ability to direct or cause the direction of the
            management or policies of that Party, whether through ownership of
            voting securities or by contract provided that in all events, the
            direct or indirect ownership of more than 50% of the paid-up and
            issued voting share capital of a Party shall be deemed to constitute
            control of such Party.
          </li>
        </ul>
      </p>
      <p>
        <strong>b. Authorized Signatory</strong> means an individual authorized
        to legally bind the Member Company.
      </p>
      <p>
        <strong>c. Member</strong> means each individual
        person/employee/official/personnel, You authorize on your Member List as
        being allowed to receive the Services (defined below).
      </p>
      <p>
        <strong>d. Membership Agreement</strong> means the supplementary
        agreement to this TUP executed by You with Us, setting out the broad
        terms & conditions. It is clarified that TUP shall not have the effect
        of superseding the terms of Membership Agreement and clauses/terms which
        are conflicting in the two documents, those contained in the Membership
        Agreement shall have an overriding effect over those in the TUP.
      </p>
      <p>
        <strong>e. Member Company or You</strong> means the company or entity
        that enters into a Membership Agreement with Us and as listed in the
        Membership Agreement.
      </p>
      <p>
        <strong>f. Regular Business Hours on Regular Business Days</strong>{" "}
        shall mean 9:00 A.M. to 8:00 P.M. from Monday to Friday except public/
        national holidays.
      </p>
      <p>
        <strong>g. Value Added Services</strong> means additional paid services
        that may be provided to the Members at the Premises/Work Spaces and
        including but not limited to Meeting rooms, Conference rooms, Event
        Space, Food & Beverages, Flexi Pass, Experience Pass, Business Address
        Registration, Parking, Storage, LAN, Air Purifiers, TV, Accounting
        Services, Legal Services, Digital Out of Home advertising and any other
        services that We may introduce from time to time.
      </p>
      <p>
        <strong>h. Work Space</strong> means the office number(s) and/or
        workspace description(s) specified in the Membership Agreement as Work
        Space Description (Work Space).
      </p>
      <p>
        <strong>i. Location or Premises</strong> means a building or portion of
        a building (including any basements, parking areas, concierge desks and
        common areas) in which We offers or plans to offer offices,
        workstations, other workspaces, and/or other services to Our Members.
      </p>
      <p>
        <strong>j. Primary Member</strong> means the primary Member contact
        located in the Premises and set out in the Member Contact Details of the
        Membership Agreement.
      </p>
      <p>
        <strong>k. Start Date</strong> means the date set forth in the
        Membership Agreement.
      </p>
      <p>
        <strong>l. Taxes</strong> means all forms of taxation and statutory,
        governmental and municipal charges, duties, and levies, wherever
        chargeable and whether originating from the Republic of India or any
        other jurisdiction; and any related penalty, fine, or surcharge.
      </p>
      <p>
        <strong>m. Our, We or Us</strong> means the BestWorks and its Affiliate
        entity(ies), You are contracting with as set forth in the Membership
        Agreement.
      </p>
      <h4>6. Benefits of Membership</h4>
      <p>
        <strong>I.</strong> Non-exclusive access to the Premises.
      </p>
      <p>
        <strong>II.</strong> Regular maintenance of the Work Space, consistent
        with the maintenance services to similar work spaces in the Premises,
        provided we will not be responsible for damages exceeding normal wear
        and tear.
      </p>
      <p>
        <strong>III.</strong> Furnishings for the Work Space of the quality and
        in the quantity typically provided to other Member Companies with
        similar work space, workstations, and/or other work space, as
        applicable, in the Premises.
      </p>
      <p>
        <strong>IV.</strong> Access to and use of the shared Internet
        connection.
      </p>
      <p>
        <strong>V.</strong> Use of the printers, copiers and/or scanners
        available to our Members and Member Companies at the Premises.
      </p>
      <p>
        <strong>VI.</strong> Use of the conference rooms in the Premises and use
        of conference rooms and other Value Added Services, in each case subject
        to requisite payment (if any), availability and your prior reservation
        of such facility.
      </p>
      <p>
        <strong>VII.</strong> Air-conditioning in the Work Space.
      </p>
      <p>
        <strong>VIII.</strong> Electricity for reasonably acceptable office use.
      </p>
      <p>
        <strong>IX.</strong> Acceptance of mail and deliveries on behalf of your
        business during Regular Business Hours on Regular Business Days,
        provided that We are not liable for any mail or packages received
        without a Company employee’s or representative’s signature indicating
        acceptance. Packages should be collected by the following day and
        storage fee may apply if packages remain uncollected over 48 hours.
      </p>
      <p>
        <strong>X.</strong> Each month, you will receive a certain number of
        credits for conference/meeting room use and a certain number of credits
        for black and white copies and printouts, and other products and
        services we may offer from time to time as specified & agreed under the
        Membership Agreement (These credits will not be rolled over from month
        to month. If these allocated credits are exceeded, you will be
        responsible for paying fees for such overages as per applicable rates at
        that time).
      </p>
      <h4>7. House Rules</h4>
      <p>
        <strong>i. Noise/ Communication</strong> - Be mindful and precise while
        engaging with other co-workers, not to use passages for calls and
        meetings this may disturb you and other co-workers as well and can
        hamper productivity.
      </p>
      <p>
        <strong>ii. Silence</strong> - Silence is the place productive people go
        to think. Please communicate freely at our meeting rooms or phone booth.
      </p>
      <p>
        <strong>iii. Tidy Desk, Tidy Mind</strong> - Please keep your workspace
        clean, tidy and neat, make sure no damages, vandalism.
      </p>
      <p>
        <strong>iv. Harassment</strong> - Please refrain from the acts of
        intimidation, harassment or discrimination in our space, online
        networks, and in all related events.
      </p>
      <p>
        <strong>v. Collaborate</strong> - Make the best use of events and grow
        your network. We do not promote multi-level-marketing approach in our
        Premises, kindly contact our community manager in order to facilitate
        promotion of your product/ services.
      </p>
      <p>
        <strong>vi. Take Care of Your Belongings</strong> - Stay cautious with
        your valuables. We shall not be responsible for any of your personal
        belongings.
      </p>
      <p>
        <strong>vii. Allotment of Working Space</strong> - Please adhere to the
        allotment of your original workspaces, Lunching and munching requested
        to be limited to dedicated lunch areas only.
      </p>
      <p>
        <strong>viii. Timings of Co-working</strong> – Kindly adhere to the
        standard operating hours of the facility, extra usage is subject to
        availability and will be charged extra.
      </p>
      <p>
        <strong>ix. Access Card</strong> - Access card is non-transferable and
        should be returned to the hub manager at the end of the membership. In
        case of reissuance the member has to pay access card lost fee + taxes to
        assign a fresh access card. It is compulsory for each co-worker to carry
        an access card all the times and no tale gating is allowed.
      </p>
      <p>
        <strong>x. Registered/ Corporate Address Rights</strong> - No member is
        allowed to use the address as the corporate or registered office
        address, in order to use the same one should opt for the different
        product, it is not a part of general seat offering.
      </p>
      <p>
        <strong>xi. Parking Services</strong> - Please take care of belongings
        inside the car and it is advised to park your car at a proper designated
        place, We will not be responsible for any kind of damage or loss.
      </p>
      <p>
        <strong>xii. Smoking Zones & After Hours</strong> - Keep smoking and
        alcohol for after parties outside of the Premises.
      </p>
      <p>
        <strong>M. You acknowledge and agree that:</strong>
        <ul>
          <li>
            i. Keys, access/key cards and other such items used to gain physical
            access to the Premises, or the Work Space remain Our property. You
            will cause your Members to safeguard Our property and you will be
            liable for replacement fees should any such property be lost, stolen
            or destroyed;
          </li>
          <li>
            ii. You shall promptly notify us of any change to your contact and
            payment information;
          </li>
          <li>
            iii. We will provide notice to you of any changes to services, fees,
            or other updates by emailing the email addresses provided by you. It
            is your responsibility to read such emails and to ensure your
            Members are aware of any changes, even if we notify such Members
            directly;
          </li>
          <li>iv. All of your Members are at least 18 years of age;</li>
          <li>
            v. carts, dollies and other freight items which may be made
            available may not be used in the passenger elevator except at our
            discretion;
          </li>
          <li>
            vi. You shall be solely and fully responsible for ensuring that no
            alcohol is consumed by any of your Members or guests who is younger
            than the legal age for consuming alcohol in the applicable
            jurisdiction;
          </li>
          <li>
            vii. Common spaces are to be enjoyed by all our Member Companies,
            members and guests unless otherwise instructed by us, and are for
            temporary use and not as a place for continuous, everyday work;
          </li>
          <li>
            viii. You will provide us with reasonable notice of and complete all
            required paperwork prior to hosting any event at the Premises;
          </li>
          <li>
            ix. You will be responsible for any damage to your Work Space
            exceeding normal wear and tear;
          </li>
          <li>
            x. You can not make any structural or non-structural alterations or
            installations of wall attachments, furniture or antennae in the Work
            Space or elsewhere in the Premises. In the event that any
            alterations or installations are made, you shall be responsible for
            the full cost and expense of the removal of such items and the
            restoration necessitated by any such alterations. To the extent that
            we incur any costs in connection with such alteration, installation
            or removal which are not otherwise paid by you we shall deduct such
            costs from the Interest Free Refundable Security Deposit. In no
            event are you permitted to perform any of these actions.;
          </li>
          <li>
            xi. You and your Members’ computers, tablets, mobile devices and
            other electronic equipment must be (i) kept up-to-date with the
            latest software updates provided by the software vendor and (ii)
            kept clean of any malware, viruses, spyware, worms, trojans, or
            anything that is designed to perform malicious, hostile and/or
            intrusive operations. We reserve the right to remove any device from
            our networks that poses a threat to our networks or users until the
            threat is remediated; and
          </li>
          <li>
            xii. For security reasons, we may, but have no obligation to,
            regularly record certain areas in the Premises via video;
          </li>
          <li>
            xiii. We may disclose information about you or your Members as
            necessary to satisfy any applicable law, rule, regulation, legal
            process or government request or as we otherwise deem reasonably
            necessary for the protection of us, other Member Companies or other
            members;
          </li>
          <li>
            xiv. you consent to our non-exclusive, non-transferable use of your
            Member Company name and/or logo in connection with identifying you
            as a Member Company, alongside those of other Member Companies, on a
            public-facing “Membership” display, as well as in other marketing
            materials. You warrant that your logo does not infringe upon the
            rights of any third party and that you have full authority to
            provide this consent. You may terminate this consent at any time
            upon thirty (30) days’ prior notice.
          </li>
          <li>
            xv. You and your Members will abide by other rules and regulations
            as determined by us and communicated to you, including by email. We
            may add, delete or amend the rules and regulations at our reasonable
            discretion and with notice to you, provided that neither the
            enforcement of such rules nor the additions, deletions or amendments
            of such rules shall be discriminatory—that is, such rules or
            additions will similarly apply to all other Members and Member
            Companies with Work Space in the Premises receiving similar
            services.
          </li>
        </ul>
      </p>
      <p>
        <strong>N. No Member Will</strong>
        <ul>
          <li>
            <strong>i. </strong>Perform any activity or cause or permit anything
            that is reasonably likely to be disruptive or dangerous to Us or any
            other Member Companies, or our or their employees, guests or
            property, including without limitation the Work Space or the
            Premises;
          </li>
          <li>
            <strike>ii. </strike> Use the Services, the Premises or the Work
            Space to conduct or pursue any illegal or offensive activities or
            comport themselves to the community in a similar manner;
          </li>
          <li>
            <strong>iii. </strong> Take, copy or use any information or
            intellectual property belonging to other Member Companies or their
            Members or guests, including without limitation any confidential or
            proprietary information, personal names, likenesses, voices,
            business names, trademarks, service marks, logos, trade dress, other
            identifiers or other intellectual property, or modified or altered
            versions of the same, and this provision will survive termination of
            this Agreement;
          </li>
          <li>
            <strong>iv. </strong> Take, copy or use for any purpose Our name or
            any of Our other business names, trademarks, service marks, logos,
            trade dress, other identifiers or other intellectual property or
            modified or altered versions of the same, or take, copy or use for
            any purpose any pictures or illustrations of any portion of the
            Premises, without our prior consent, and this provision will survive
            termination of this Agreement;
          </li>
          <li>
            <strong>v. </strong> Use the Work Space in a “retail,” “medical,” or
            other nature involving frequent visits by members of the public;
          </li>
          <li>
            <strong>vi. </strong> Sell, manufacture or distribute any controlled
            substance, including alcoholic beverages, from the Work Space, or
            obtain a license for such sale, manufacture, importation, or
            distribution using the Work Space or the address of the Premises;
          </li>
          <li>
            <strong>vii. </strong> Use our mail and deliveries services for
            fraudulent or unlawful purposes, and we shall not be liable for any
            such use;
          </li>
          <li>
            <strong>viii. </strong> Make any copies of any keys, access/key
            cards or other means of entry to the Work Space or the Premises or
            lend, share or transfer any keys or access/key cards to any third
            party, unless authorized by us in advance;
          </li>
          <li>
            <strong>ix. </strong> Install any locks to access the Work Space or
            anywhere within the Premises, unless authorized by us in advance;
          </li>
          <li>
            <strong>x. </strong> Bring any weapons of any kind, or any other
            offensive, dangerous, inflammable or explosive materials into the
            Work Space or the Premises;
          </li>
          <li>
            <strong>xi. </strong> You are responsible for ensuring your Members
            comply with all House Rules.
          </li>
        </ul>
      </p>
      <h4>8. Membership Fees</h4>
      <p>
        <strong>1. Membership fee.</strong> During the Term of this Agreement,
        your Membership Fee will be due monthly and in advance as of the seventh
        (7th) day of each month. You are obligated to make payment of all
        Membership Fees owed throughout the Lock-in Period and this obligation
        is absolute notwithstanding any early termination of the Agreement by
        you (“Membership Fee Obligations”). You agree to pay promptly: (i) all
        applicable taxes which you are required to pay to any other governmental
        authority (and, at our request, will provide to us evidence of such
        payment) and (ii) all taxes attributable to your Membership as shown on
        your invoice. The Membership Fee set forth on the Membership Agreement
        covers the Services for only the number of seats indicated in the
        Membership Agreement. Additional seats will result in additional fees as
        set forth by Company from time to time.
      </p>
      <p>
        <strong>
          2. On each anniversary of the Monthly Usage Charge Commencement Date
        </strong>{" "}
        (including during any Lock-in Period) the Membership Fee will be subject
        to escalation at the rate mentioned in the Membership Agreement.
        Following any Lock-in Period, we reserve the right to further increase
        or decrease the Membership Fee at our sole discretion upon thirty (30)
        days’ prior notice to you.
      </p>
      <p>
        <strong>3. Invoices.</strong> The Company will send or otherwise provide
        invoices and other billing-related documents, information and notices to
        the Primary Member, unless a different Billing Contact is indicated on
        the Membership Agreement. Change of the Billing Contact will require
        notice from the Authorized Signatory in accordance with this Agreement.
      </p>
      <p>
        <strong>4. Payment.</strong> We accept payment of all amounts specified
        in this Agreement solely by the methods we communicate to you during the
        membership sign up process or from time to time during the Term.
      </p>
      <p>
        <strong>5. Outstanding Fees.</strong> Any outstanding fees will be
        charged in arrears on a monthly basis. When we receive funds from you,
        we will first apply funds to any balances which are in. Once past
        balances are satisfied, any remaining portion of the funds will be
        applied to current fees due. If any payments remain outstanding after we
        provide notice to you, we may, in our sole discretion, withhold Services
        or terminate the Membership Agreement in accordance therewith.
      </p>
      <p>
        <strong>6. No Refunds.</strong> Except after expiration of Lock-in
        period or Termination or Suspension by Us of this Agreement, there are
        no refunds of any fees or other amounts paid by you or your Members in
        connection with the Services.
      </p>
      <p>
        <strong>7. Cross Default.</strong> You agree that, if you are under
        default under the Membership Agreement with Company, at a different
        office location, (“Different Location Agreement”) to the Office
        Location/Number specified in the Agreement, that the Company may recover
        any unpaid sums due to Different Location Agreement from you under this
        Agreement and that the Company may, in particular (but not limited to),
        withhold services under this Agreement or deduct sums from the Interest
        Free Refundable Security Deposit held in this Agreement in respect of
        such unpaid sums.
      </p>
      <h4>
        9. Changes to or Removal of Primary Member or Authorized Signatory
      </h4>
      <p>
        An Authorized Signatory generally has the sole authority to make changes
        to or terminate this Agreement. A Primary Member will generally serve as
        Company’s primary contact regarding matters that involve your Members,
        the physical Work Space or the Premises. We will be entitled to rely on
        communications to or from the Authorized Signatory or Primary Member as
        notice to or from the applicable Member Company.
      </p>
      <h4>
        10. Work Space Not Timely Available or Unavailable due to certain events
      </h4>
      <p>
        Notwithstanding anything in the Agreement, if we delay in making the
        Work Space available by the Monthly Membership Fee Commencement Date or
        are unable to provide you access to the Work Space at any time during
        the Term of the Agreement due to Force Majeure event or any other event
        that may have affected Our right to make the Work Space available for a
        period of more than (1) month, we will not be subject to any liability
        related to such inability, provided that, at our sole discretion We will
        either (i) provide you with alternate work space (which may or may not
        be within the Building Premises) with reasonably comparable Capacity and
        charge your Membership Fee or (ii) not charge you the Membership Fee
        during the period the Work Space is not available to you in case of
        delay in making the Work Space available before Your move-in. Upon the
        completion of one (1) month period, if You decide not to shift to the
        alternate work space as offered in (1) above, the Agreement shall stand
        terminated, without any liability of either Party of Membership Fee for
        the unexpired Commitment Term. Notwithstanding anything in this
        paragraph to the contrary, if the delay/inability in providing the Work
        Space is due to Your actions or inactions or due to changes in work to
        the Work Space requested by You, We will not be subject to any liability
        related to such delay nor will such delay affect the validity of this
        Agreement and we shall have no obligations to provide you with the
        benefits described in subsections (i) and (ii) of this paragraph.
      </p>
      <h4>11. Updating the Member List</h4>
      <p>
        Only those individuals set forth on the Member List will be deemed to be
        “Members” and entitled to the benefits described in this Agreement. Your
        Members will be able to begin using, accessing, and/or receiving the
        Services on the later of:
      </p>
      <ul>
        <li>i. the Monthly Usage Charge Commencement Date or</li>
        <li>
          ii. the date we confirm the addition of such individual to the Member
          List.
        </li>
      </ul>
      <p>
        You are responsible for maintaining the accuracy of the Member List. To
        add new Members to your Member List in excess of the number allocated on
        the Membership Agreement, you must have your Primary Member send an
        email, from the Primary Member’s email account on file with Company, to
        the Company. The email requesting the change must include the name(s)
        and email address(es) of the departing and new Member(s) and the
        effective date of the change.
      </p>
      <h4>12. Our Reserved Rights</h4>
      <p>
        We are entitled to access your Work Space, with or without Notice, in
        connection with our provision of the Services, for safety or emergency
        purposes or for any other purposes. We reserve the right to alter your
        Work Space, provided that we will not do so in a manner that
        substantially decreases the square footage of your assigned Work Space
        or related amenities. We may also modify or reduce the list of Services
        or furnishings provided for your Work Space at any time. The Services
        may be provided by us, an affiliate or a third party.
      </p>
      <h4>13. Harassment</h4>
      <p>
        The Member Company and its Members shall be prohibited from
        participating in any type of harassing, discriminatory, or abusive
        behaviour to the Company employees & representatives, other Members or
        invitees, verbal or physical in our centre for any reason. Any breach of
        this condition is a material breach of the Agreement (not capable of
        remedy) and the Agreement may be terminated immediately and services
        will be suspended without further notice.
      </p>{" "}
      <h4>14. Fraud</h4>
      <p>
        We reserve the right to immediately suspend the services and/or
        terminate the Agreement if they determine that their facility or address
        is being used in connection with possible fraudulent activity or
        activity that may be in violation of laws or government regulations.
      </p>
    </div>
  );
};

export default TermsOfUse;
