import React from "react";
import "../css/Privacy.css";

const PrivacyPolicy = () => {
  return (
    <div className="container" id="privacy-policy">
      <h1>Privacy Policy</h1>
      <p>
        BestWorks is committed to protecting your information. This privacy
        notice provides details about the information we collect about you, how
        we use it and how we protect it. It also explains your rights and how to
        contact us if you have questions about how we use your information.
      </p>
      <h4>Table of Contents:</h4>
      <ol>
        <li>1. Data protection contacts</li>
        <li>2. Information about BestWorks</li>
        <li>3. Scope of our privacy notice</li>
        <li>4. Ways in which we obtain personal information</li>
        <li>5. Categories of information</li>
        <li>
          6. Purposes and lawful grounds of our processing personal information
        </li>
        <li>7. Legitimate interests</li>
        <li>8. Marketing and Preferences</li>
        <li>9. Analytics and Advertising Services Provided by Others</li>
        <li>10. Sharing your information</li>
        <li>11. Your rights</li>
      </ol>
      <h4>1. Data protection contacts</h4>
      <p>
        If you have any questions, comments, complaints or suggestions in
        relation to this notice, or any other concerns about the way in which we
        process information about you, please contact us. If you are not
        satisfied with the response from our Privacy Team, you can contact us.
        You also have a right to make a complaint to your local privacy
        supervisory authority. If you are in the EU, you can lodge a complaint
        with another supervisory authority which is based in the country or
        territory where:
      </p>
      <ul>
        <li>You are living</li>
        <li>You work, or</li>
        <li>The alleged infringement took place.</li>
      </ul>
      <h4>2. Information about BestWorks</h4>
      <p>
        In this privacy notice, references to “BestWorks”, “BestWorks India
        CoWorking”, "we" or "us" or “our” are to BestWorks and its parent
        company and its subsidiaries (the “ Mention the Name here ”). Depending
        on where and how you interact with us (e.g. by using our app or by
        walking into our workspace centre/hub (“workspace”) or using workspaces
        via a third party agency)
      </p>
      <h4>3. Scope of our privacy notice</h4>
      <p>
        This notice applies to anyone who interacts with us in relation to our
        products and services (“you”, “your”), via any channel (e.g. app,
        website, email, phone, walk-ins or via a third party etc.). This privacy
        notice applies to entities who enquire about, purchase or make use of
        our products and services, such as when a user signs up with us,
        requests on-demand services, contacts customer support, or otherwise
        communicates or interacts with us. It describes how we handle your
        information, regardless of the way you contact us (e.g. app, website,
        email, phone, walk-ins etc.). Sometimes we will provide you with
        additional information or notices, depending on the way we interact with
        each other, e.g. if you use particular functionality on our app, we may
        explain how that functionality uses your information. BestWorks’s
        website and app is not aimed at children, and we do not allow children
        to book workspaces without a responsible adult. This privacy notice is
        therefore not aimed at children. If you provide us with information
        about your children, it is processed as described in this notice. If you
        have any questions about this, please contact us.
      </p>
      <h4>4. Ways in which we obtain personal information</h4>
      <p>
        We obtain personal information from you and from agents and others who
        book workspaces for you. Where you provide us with information about
        other individuals (e.g. your employees/partners, co-workers who will use
        the workspace), you must ensure that they have seen a copy of this
        privacy policy and are comfortable with you doing this. We obtain
        personal information from you through your interactions with us,
        including by telephone (please note that we record or monitor our calls
        for compliance and quality assurance purposes), by email, via our
        websites, via our apps, via post, via, social media or face to face. We
        also obtain your personal information from third parties such as:
      </p>
      <ul>
        <li>Agencies through which you book workspaces</li>
        <li>
          Service providers who work with us in relation to our product or
          service
        </li>
      </ul>
      <h4>5. Categories of information</h4>
      <p>
        <strong>Contact Information:</strong> We collect your name, user name,
        address, e-mail address and telephone number(s), Whats-app contact
        number.
      </p>
      <p>
        <strong>Location Information:</strong> We may derive your approximate
        location from your IP address so that we show you more relevant content
        (e.g. workspaces near you). You can choose to allow our app to access
        location services so that we can suggest nearby workspaces. This will
        mean that we collect the precise location of your device when the
        app/website is running both in the foreground and the background.
      </p>
      <p>
        <strong>Transaction Information:</strong> We collect transaction details
        related to your use of our services, e.g. type of service requested,
        date and time the service was provided, amount charged, and other
        related transaction details. If someone else uses an BestWorks referral
        code you have given them, BestWorks may associate that other person with
        you to ensure that you receive the benefit of having made that referral.
      </p>
      <p>
        <strong>Account History:</strong> We collect details of your bookings
        and of contact we have had with you, such as complaints or incidents.
      </p>
      <p>
        <strong>Usage and Preference Information:</strong> We collect
        information about how you interact with our website, app or other
        Services, including IP addresses, search terms and parameters and device
        information (you’ll find more information about this in our Cookie
        Statement)
      </p>
      <p>
        <strong>Log Information:</strong> We collect server logs, which may
        include information like device IP address, access dates and times, app
        features or pages viewed, app crashes and other system activity, type of
        browser, and the third-party site or service you were using before
        interacting with our Services.
      </p>
      <p>
        <strong>Device Information:</strong> We collect information about your
        mobile device, including, for example, the hardware model, operating
        system and version, software and file names and versions, preferred
        language, advertising identifiers, device motion information, and mobile
        network information to help us understand how our website/app is
        performing across a range of devices and to help us identify the cause
        of “crashes” and potential performance improvements.
      </p>
      <p>
        <strong>Call and SMS Data:</strong> We make it easier for you to contact
        centre managers. In connection with this, we receive call data,
        including the date and time of the call or SMS message, the parties’
        phone numbers, and the content of the SMS message.
      </p>
      <p>
        <strong>CCTV:</strong> We may also collect your personal information if
        you access our premises and we use CCTV to monitor those premises.
      </p>
      <p>
        <strong>WiFi:</strong> on your use of WiFi offered at our workspaces, we
        will collect login information (which may include your email) and usage
        information that tells us how you use the WiFi.
      </p>
      <p>
        Sometimes you may choose to give us special category information about
        you or others which may attract extra protections under data protection
        laws.
      </p>
      <h4>
        6. Purposes and lawful grounds of our processing personal information
      </h4>
      <p>
        We process your personal information for the purposes set out in this
        notice, based on the legal grounds set out under the Read More text.
        Different legal grounds apply depending on what category of personal
        information we process. Standard personal information is normally
        processed by us on the basis that it is necessary for the performance of
        a contract, our or a third parties’ legitimate interests or law. Further
        information about this and special category processing grounds is set
        out below.
      </p>
      <p>
        We process standard personal information about you on the basis that it
        is:
      </p>
      <ul>
        <li>
          Necessary for the performance of a contract: where we have a contract
          with you, we will process your personal information in order to fulfil
          that contract (i.e. to provide you and others you have booked
          workspaces for with products and services).
        </li>
        <li>
          In our or a third parties’ legitimate interests: details of those
          legitimate interests are set out in more detail below.
        </li>
        <li>Where we are required to or is otherwise permitted by law</li>
      </ul>
      <p>We process special category information because:</p>
      <ul>
        <li>You have manifestly made it public:</li>
        <li>
          Processing is necessary for reasons of substantial public interest, on
          the basis of law: for example, if you are taken ill or have an
          accident at your workspaces, we may have to keep a record of the
          incident for health and safety purposes;
        </li>
        <li>
          We have your consent: we would ask for any consent to process your
          special category data separately (your consent is not assumed simply
          because you have read this privacy notice);
        </li>
        <li>
          Processing is necessary for the establishment, exercise or defence of
          a legal claims
        </li>
      </ul>
      <h4>7. Legitimate interests</h4>
      <p>
        We process your personal information for a number of legitimate
        interests, including providing you with services, administering our
        relationship with you, for marketing and service/product improvement
        purposes and in order to exercise our rights or handle claims. More
        detailed information about our legitimate interests is set out below.
      </p>
      <p>
        We process your personal information for the following legitimate
        interests (taking into account your interests, rights and freedoms):
      </p>
      <ul>
        <li>
          To provide and administer our products and services in respect of your
          using e.g. provide products and services requested, send service
          communications (including updates about your workspaces arrangements),
          facilitate payments, send receipts and provide customer support;
        </li>
        <li>
          To ensure that you can interact with us securely, e.g. we authenticate
          users;
        </li>
        <li>
          To improve our services and develop new features for the app, website
          and offering, including to troubleshoot software bugs and operational
          problems; to conduct data analysis, testing, and research; and to
          monitor and analyse usage and activity trends;
        </li>
        <li>
          To send you marketing communication that we think will be of interest
          to you (including information about products, services, promotions,
          news, and events) and to personalise your experience of us (in each
          case as permitted by law), including to identify your preferences,
          provide or recommend features, content, social connections, referrals,
          and advertisements. You can opt-out of marketing at any time by
          clicking the unsubscribe link in any emails you receive, adjusting
          your device settings to block notifications or contacting us.
        </li>
        <li>To prevent fraud and abuse of our services;</li>
        <li>
          To enforce or apply our website terms of use, our policy terms and
          conditions or other contracts, or to protect the rights, property, or
          safety of BestWoks, our customers, or others;
        </li>
        <li>To exercise our rights and to defend ourselves from claims; or</li>
        <li>
          To participate in, or be the subject of, any sale, merger or
          acquisition or all or part of the BestWorks business.
        </li>
      </ul>
      <p>
        To ensure that the processing of personal information we carry out for
        the purpose of achieving these interests is proportionate, we have
        carefully balanced these legitimate interests and your interests and
        fundamental rights and freedoms. For further details of this balancing
        test, please contact us.
      </p>
      <h4>8. Marketing and Preferences</h4>
      <p>
        We use your personal information to send you marketing by post,
        telephone, social media platforms, email, SMS, application notifications
        and silent notifications to track installation status of the
        application. We may also use your personal information to carry out
        online paid marketing activities in the nature of retargeting. We also
        use technology to help us understand your personal preferences and
        interests so that we can send recommendations and marketing
        communications that are likely to be of more interest to you. Using
        technology in this way is sometimes known as profiling. If you wish to
        unsubscribe from emails sent by us, you can do so at any time by
        clicking on the "unsubscribe" link that appears in all emails and by
        adjusting the settings on your device to turn off notifications from our
        app. Otherwise, you can always contact us to update your contact
        preferences. You may also object to profiling relating to direct
        marketing by contacting us.
      </p>
      <h4>9. Analytics and Advertising Services Provided by Others</h4>
      <p>
        Third parties provide us with audience measurement and analytics
        services that analyse your online interactions (both with the BestWorks
        and other websites), serve you with tailored marketing and report on the
        performance of that marketing. We also use Google Place API to make
        suggestions based on your location or search strings.
      </p>
      <p>
        We may allow others to provide audience measurement and analytics
        services for us, to serve advertisements on our behalf across the
        Internet, and to track and report on the performance of those
        advertisements. These entities may use cookies, web beacons, SDKs, and
        other technologies to identify your device when you visit our site and
        use our Services, as well as when you visit other online sites and
        services.
      </p>

      <h4>10. Sharing your information</h4>
      <p>
        We share your information within the BestWorks and with others who help
        us provide services to you (e.g. your channel partners, brokers etc). We
        also share your information in accordance with the law. We share your
        information for the purposes set out in this privacy policy, with the
        following categories of recipients:
      </p>
      <ul>
        <li>Other members of BestWorks;</li>
        <li>
          With Workspaces to enable them to provide the services you request,
          e.g.;
        </li>
        <li>
          Third party suppliers who help deliver products or services on our
          behalf, or who have partnered with BestWorks in connection with
          services or a relevant promotional offering, as well as other apps or
          websites that integrate with our API or Services, or those with an API
          or Service with which we integrate;
        </li>
        <li>Your contact if you use a referral feature;</li>
        <li>
          The general public, if you submit content in a public forum, such as
          blog comments, social media posts, or other features of our services
          that are obviously viewable by the general public. For example, you
          may choose to use social sharing features and related tools which let
          you share your BestWorks experience with other apps, sites, or media,
          and vice versa. Your use of these features enables the sharing of
          information with your friends or the public, depending on your social
          sharing service settings. Please refer to the privacy policies of
          those social sharing services for more information about how they
          handle the data you provide to or share through them;
        </li>
        <li>
          Third parties where we have a duty to or are permitted to disclose
          your personal information by law (e.g. law enforcement officials,
          government authorities);
        </li>
        <li>
          With the police in the exercise of their functions or with others as
          required by a court order;
        </li>
        <li>
          Third parties in connection with any merger, sale of company assets,
          consolidation or restructuring, financing, or acquisition of all or a
          portion of the BestWorks business, in which case personal data held by
          it about its customers or visitors to the website may be one of the
          transferred assets. Where we share your personal information,
          appropriate protections will be in place to protect your personal
          information as required by data protection laws. Sometimes, we may
          share information in an aggregated, pseudonymised and/or anonymised
          form which cannot reasonably be used to identify an individual.
        </li>
      </ul>

      <h4>11. Your rights</h4>
      <p>
        Data protection laws in the EEA and some other countries provide
        individuals with rights in relation to the information that BestWorks
        holds about them on some computer and paper records. These include
        rights to access, correct, delete, restrict the use of, object to the
        use of, port to another person, and withdraw consent to the use of, your
        information. Exceptions may apply to these rights.
      </p>
      <p>
        The data protection laws in the EEA and in some other countries provide
        individuals with the following rights:
      </p>
      <ul>
        <li>
          Right of subject access: The right to make a written request for
          details of personal information we hold about you and to request a
          copy of that personal information.
        </li>
        <li>
          Right to rectification: The right to have inaccurate information about
          you rectified.
        </li>
        <li>
          Right to restriction of processing: The right to request that your
          personal information is only used for restricted purposes.
        </li>
        <li>
          Right to object: The right to object to the use of personal
          information (including the right to object to marketing and profiling
          relating to direct marketing).
        </li>
        <li>
          Right to data portability: The right to ask for personal information
          you have made available to us to be transferred to you or a third
          party in machine-readable formats.
        </li>
        <li>
          Right to withdraw consent: We do not normally rely on consent as a
          basis for processing your personal information. We will only ask for
          your consent in very limited circumstances and, if we do so, will make
          it obvious to you when we are asking for that consent and what that is
          for. You have the right to withdraw any consent you have given us to
          handle your personal information. If you withdraw your consent, this
          will not affect the lawfulness of use of your personal information
          prior to the withdrawal of your consent.
        </li>
      </ul>
      <p>
        These rights may not apply in all cases. If we do not comply with your
        request, we will explain why. In response to a request, we will ask you
        to verify your identity if we need to, and to provide information that
        helps us to understand your request better. If you would like more
        information about your rights or to exercise any of your rights, please
        contact us.
      </p>
    </div>
  );
};

export default PrivacyPolicy;
