import React from "react";
import withLayout from "../hoc/withLayout";
import Hero from "../components/Hero";
import Features from "../components/Features";
import OurBenefits from "../components/OurBenefits";
import Gallery from "../components/Gallery";
import Solution from "../components/Solution";
import Testimonials from "../components/Testimonials";
import FaqQuestions from "../components/FaqQuestions";

const Home = () => {
  return (
    <>
      <div class="bg-circle"></div>
      <Hero />
      <Features />
      <OurBenefits />
      <Gallery />
      <Solution />
      <Testimonials />
      <FaqQuestions />
    </>
  );
};

export default withLayout(Home);
