import React from "react";
import withLayout from "../hoc/withLayout";
import { Link } from "react-router-dom";
import SpacesConfiguration from "../components/SpacesConfiguration";

const Spaces = () => {
  return (
    <div>
      {" "}
      <div className="banner spaces_banner">
        <div className="container">
          <h2>OUR SPACES</h2>
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/">Home</Link>
              </li>
              <li className="breadcrumb-item" aria-current="page">
                Spaces
              </li>
            </ol>
          </nav>
        </div>
      </div>
      <SpacesConfiguration />
    </div>
  );
};

export default withLayout(Spaces);
