import React from "react";

const Gallery = () => {
  return (
    <section className="gallery">
      <div className="container">
        <h5 className="text-center h6-color">Get Inspired</h5>
        <h2>
          Visualize Your <span className="active-color">Ideal Workspace:</span>
          Explore Our Gallery
        </h2>
        <p className="gallery-p">
          Lorem Ipsum is simply dummy text of the printing and typesetting
          industry. Lorem Ipsum has been the industry's.
        </p>
        <div className="row">
          <div className="col-lg-8 col-md-8">
            <div className="row">
              <div className="col-lg-12">
                <div className="col-lg-12 mb-4">
                  <figure>
                    <img
                      src="assets/images/gallery/gallery10.jpg"
                      alt="image"
                    />
                  </figure>
                </div>
                <div className="row">
                  <div className="col-lg-6 col-md-6 col-sm-12 d-flex flex-lg-column flex-md-column flex-sm-row flex-column gap-4 mb-lg-0 mb-md-4 mb-sm-4 mb-4">
                    <figure>
                      <img
                        src="assets/images/gallery/gallery2.jpeg"
                        alt="image"
                      />
                    </figure>
                    <figure>
                      <img
                        src="assets/images/gallery/gallery3.jpeg"
                        alt="image"
                      />
                    </figure>
                  </div>
                  <div className="col-lg-6 col-md-6">
                    <figure>
                      <img
                        src="assets/images/gallery/gallery8.jpg"
                        alt="image"
                      />
                    </figure>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-4 d-flex flex-column gap-4">
            <figure>
              <img src="assets/images/gallery/gallery9.jpeg" alt="image" />
            </figure>
            <figure>
              <img src="assets/images/gallery/gallery11.jpg" alt="image" />
            </figure>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Gallery;
