import React from "react";
// import Swiper core and required modules
import { Navigation, Pagination, Scrollbar, A11y } from "swiper/modules";

import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
const CustomSwiper = ({ slides, settings }) => {
  return (
    <Swiper
      // install Swiper modules
      modules={[Navigation, Pagination, Scrollbar, A11y]}
      {...settings}
      className="swiper-container"
      style={{ padding: "0 20px" }}
    >
      {slides.map((slide, index) => (
        <SwiperSlide key={index} className="swiper-slide">
          {slide}
        </SwiperSlide>
      ))}
    </Swiper>
  );
};

export default CustomSwiper;
